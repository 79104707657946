<template>
  <div id="cadastro">
    <button id="voltar" @click.prevent="$emit('fechar-form-atividade')">voltar</button>
    <form class="form" @submit.prevent="handleSubmit">
      <h4>Cadastrar Atividade</h4>
      <div id="campos-form">
        <div class="mb-3">
          <label for="nome" class="form-label">Nome</label>
          <input type="text" id="nome" class="form-control" v-model="formData.nome" required />
        </div>

        <div class="mb-3">
          <label for="descricao" class="form-label">Descrição</label>
          <textarea id="descricao" class="form-control" v-model="formData.descricao" required></textarea>
        </div>

        <div class="mb-3">
          <label for="tipo" class="form-label">Tipo</label>
          <select id="tipo" class="form-select" v-model="formData.tipo" required>
            <option v-for="tipo in Campos.tipos" :value="tipo.value">{{ tipo.nome }}</option>
          </select>
        </div>

        <div class="mb-3">
          <label for="modalidade-execucao" class="form-label">Modalidade</label>
          <select id="modalidade-execucao" class="form-select" v-model="formData.modalidadeExecucao" required>
            <option v-for="modalidade in Campos.Modalidades" :value="modalidade.value">{{ modalidade.nome }}</option>
          </select>
        </div>

        <div class="mb-3">
          <label for="modalidade-inscricao" class="form-label">Modalidade de Inscrição</label>
          <select id="modalidade-inscricao" class="form-select" v-model="formData.modalidadeInscricao" required>
            <option v-for="modalidadeInscricao in Campos.ModalidadesInscricao" :value="modalidadeInscricao.value">{{ modalidadeInscricao.nome }}</option>
          </select>
        </div>

        <div v-if="formData.modalidadeInscricao === 'NESCESSITA_INSCRICAO'">
          <div class="mb-3">
            <label for="dataInicioInscricao" class="form-label">Data de Início das Inscrições</label>
            <input type="datetime-local" id="dataInicioInscricao" class="form-control" v-model="formData.dataInicioInscricao" required />
          </div>

          <div class="mb-3">
            <label for="dataFimInscricao" class="form-label">Data de Fim das Inscrições</label>
            <input type="datetime-local" id="dataFimInscricao" class="form-control" v-model="formData.dataFimInscricao" required />
          </div>

          <div class="mb-3">
            <label for="criterioInscricao" class="form-label">Critério de Inscrição</label>
            <select id="criterioInscricao" class="form-select" v-model="formData.criterioInscricao" required>
              <option v-for="criterio in Campos.CriteriosInscricao" :value="criterio.value">{{ criterio.nome }}</option>
            </select>
          </div>

          <div class="mb-3">
            <label for="numeroDeVagasOfertadas" class="form-label">Número de Vagas</label>
            <input type="number" id="numeroDeVagasOfertadas" class="form-control" v-model.number="formData.numeroDeVagasOfertadas" min="1" required />
          </div>
        </div>

        <div class="mb-3">
          <label for="local" class="form-label">Local</label>
          <input type="text" id="local" class="form-control" v-model="formData.local" required />
        </div>

        <div class="mb-3">
          <label for="ocorrencias" class="form-label">Ocorrências</label></br>
          <button type="button" class="btn btn-secondary" @click="addOcorrencia">Adicionar Ocorrência</button>
          <div v-for="(ocorrencia, index) in formData.ocorrencias" :key="index" class="mt-3">
            <textarea v-model="ocorrencia.descricao" class="form-control" placeholder="Descrição"></textarea>
            <input type="datetime-local" v-model="ocorrencia.inicio_periodo" class="form-control mt-2" required />
            <input type="datetime-local" v-model="ocorrencia.fim_periodo" class="form-control mt-2" required />
            <button type="button" class="btn btn-danger mt-2" @click="removeOcorrencia(index)">Remover</button>
          </div>
        </div>

        <div class="mb-3">
          <label for="imagem" class="form-label">Imagem</label>
          <input type="file" id="imagem" class="form-control" accept="image/*" @change="processImage" />
        </div>

        <div class="mb-3" v-if="formData.imagem.base64Data">
          <label for="creditos-img" class="form-label">Créditos da Imagem</label>
          <input type="text" id="creditos-img" class="form-control" v-model="formData.imagem.creditosImagem" />
        </div>
      </div>

      <div id="botoes" style="justify-content: center;">
        <button id="enviar" type="submit" class="btn btn-primary center">Enviar</button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  props: {
    acaoId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      formData: {
        nome: "",
        descricao: "",
        tipo: "",
        modalidadeExecucao: "",
        modalidadeInscricao: "",
        local: "",
        acaoExtensaoId: this.acaoId,
        dataInicioInscricao: null,
        dataFimInscricao: null,
        criterioInscricao: null,
        numeroDeVagasOfertadas: null,
        ocorrencias: [],
        imagem: {
          mimeType: "",
          base64Data: "",
          creditosImagem: "",
        },
      },
      Campos: {
        tipos: [
          { value: "CURSO", nome: "Curso" },
          { value: "MINICURSO", nome: "Minicurso" },
          { value: "EVENTO", nome: "Evento" },
          { value: "OFICINA", nome: "Oficina" },
          { value: "ENCONTRO", nome: "Encontro" },
          { value: "RODADECONVERSA", nome: "Roda de Conversa" },
        ],
        Modalidades: [
          { value: "REMOTA", nome: "Remota" },
          { value: "PRESENCIAL", nome: "Presencial" },
          { value: "HIBRIDA", nome: "Híbrida" },
        ],
        ModalidadesInscricao: [
          { value: "NESCESSITA_INSCRICAO", nome: "Com Inscrição" },
          { value: "NAO_NESCESSITA_INSCRICAO_COM_FREQUENCIA", nome: "Sem Inscrição (Com Frequência)" },
          { value: "NAO_NESCESSITA_INSCRICAO_SEM_FREQUENCIA", nome: "Sem Inscrição (Sem Frequência)" },
        ],
        CriteriosInscricao: [
          { value: "ORDEMDEINSCRICAO", nome: "Ordem de Inscrição" },
          { value: "CRITERIODACOORDENACAO", nome: "Critério da Coordenação" },
        ],
      },
    };
  },
  methods: {
    async handleSubmit() {
      try {
        const payload = JSON.parse(JSON.stringify(this.formData));
        if (payload.modalidadeInscricao !== "NESCESSITA_INSCRICAO") {
          payload.dataInicioInscricao = null;
          payload.dataFimInscricao = null;
          payload.criterioInscricao = null;
          payload.numeroDeVagasOfertadas = null;
        }
        if (!payload.imagem.base64Data) {
          payload.imagem = null;
        }

        const response = await this.$api.post("/atividade", payload);
        alert("Atividade criada com sucesso!");
        this.resetForm();
      } catch (error) {
        console.error("Erro ao criar atividade:", error.response?.data || error);
      }
    },
    processImage(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          this.formData.imagem.mimeType = file.type;
          this.formData.imagem.base64Data = reader.result.split(",")[1];
        };
        reader.onerror = () => alert("Erro ao processar a imagem.");
        reader.readAsDataURL(file);
      }
    },
    addOcorrencia() {
      this.formData.ocorrencias.push({ descricao: "", inicio_periodo: "", fim_periodo: "" });
    },
    removeOcorrencia(index) {
      this.formData.ocorrencias.splice(index, 1);
    },
    resetForm() {
      this.formData = {
        nome: "",
        descricao: "",
        tipo: "",
        modalidadeExecucao: "",
        modalidadeInscricao: "",
        local: "",
        acaoExtensaoId: this.acaoId,
        dataInicioInscricao: null,
        dataFimInscricao: null,
        criterioInscricao: null,
        numeroDeVagasOfertadas: null,
        ocorrencias: [],
        imagem: {
          mimeType: "",
          base64Data: "",
          creditosImagem: "",
        },
      };
    },
  },
};
</script>

<style scoped></style>
