<template>
  <div id="cadastro">
    <button id="voltar" @click.prevent="$emit('fechar-form-atividade')"> voltar </button>
    <form class="form" @submit.prevent="handleSubmit">
      <h4>Cadastrar Atividade</h4>
      <div id="campos-form">
        <div class="mb-3">
          <label for="nome" class="form-label">Nome</label>
          <input type="text" id="nome" class="form-control" v-model="formData.nome" required />
        </div>

        <div class="mb-3">
          <label for="descricao" class="form-label">Descrição</label>
          <textarea id="descricao" class="form-control" v-model="formData.descricao" required></textarea>
        </div>

        <div class="mb-3">
          <label for="tipo" class="form-label">Tipo</label>
          <select id="tipo" class="form-select" v-model="formData.tipo" required>
            <option v-for="tipo in Campos.tipos" :value="tipo.value">{{ tipo.nome }}</option>
          </select>
        </div>

        <div class="mb-3">
          <label for="modalidade" class="form-label">Modalidade</label>
          <select id="modalidade" class="form-select" v-model="formData.modalidadeExecucao" required>
            <option v-for="modalidade in Campos.Modalidades" :value="modalidade.value">{{ modalidade.nome }}</option>
          </select>
        </div>

        <div class="mb-3">
          <label for="modalidade" class="form-label">Modalidade de Inscrição</label>
          <select id="modalidade" class="form-select" v-model="formData.modalidadeInscricao" required>
            <option v-for="modalidadeInscricao in Campos.ModalidadesInscricao" :value="modalidadeInscricao.value">{{
              modalidadeInscricao.nome }}</option>
          </select>
        </div>

        <div class="mb-3">
          <label for="local" class="form-label">Local</label>
          <input type="text" id="local" class="form-control" v-model="formData.local" required />
        </div>

        <div class="mb-3">
          <label for="dataInicio" class="form-label">Data de Início</label>
          <input type="date" id="dataInicio" class="form-control" v-model="formData.dataInicio" required />
        </div>

        <div class="mb-3">
          <label for="dataFim" class="form-label">Data de Fim</label>
          <input type="date" id="dataFim" class="form-control" v-model="formData.dataFim" required />
        </div>

        <div class="mb-3">
          <label for="imagem" class="form-label">Imagem</label>
          <input type="file" id="imagem" class="form-control" accept="image/*" @change="processImage" />
        </div>

        <div class="mb-3" v-show="formData.imagem.base64Data != ''">
          <label for="local" class="form-label">Créditos imagem</label>
          <input type="text" id="creditos-img" class="form-control" v-model="formData.imagem.creditosImagem" />
        </div>

      </div>
      <div id="botoes">
        <button id="excluir" @click.prevent="cancelarAtividade">Cancelar</button>
        <button id="enviar" type="submit" class="btn btn-primary center" :disabled="atualizando">Enviar</button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  props: {
    dados: Object,
    acaoId: String
  },
  data() {
    return {
      formData: {
        nome: this.dados.nome,
        descricao: this.dados.descricao,
        tipo: this.dados.tipo,
        status: this.dados.status,
        modalidadeExecucao: this.dados.modalidadeExecucao,
        modalidadeInscricao: this.dados.modalidadeInscricao,
        local: this.dados.local,
        dataInicio: this.dados.dataInicio,
        dataFim: this.dados.dataFim,
        acaoExtensaoId: this.acaoId, // Corrigido
        imagem: {
          creditosImagem: '',
          mimeType: '',
          base64Data: '',
        },
      },
      Campos: {
        tipos: [
          {
            value: 'CURSO',
            nome: 'Curso'
          },
          {
            value: 'MINICURSO',
            nome: 'Minicurso'
          },
          {
            value: 'EVENTO',
            nome: 'Evento'
          },
          {
            value: 'OFICINA',
            nome: 'Oficina'
          },
          {
            value: 'ENCONTRO',
            nome: 'Encontro'
          }
        ],
        Modalidades: [
          {
            value: 'REMOTA',
            nome: 'Remota'
          },
          {
            value: 'PRESENCIAL',
            nome: 'Presencial'
          },
          {
            value: 'HIBRIDA',
            nome: 'Hibrida'
          }
        ],
        ModalidadesInscricao: [
          {
            value: 'NESCESSITA_INSCRICAO',
            nome: 'Com Inscrição'
          },
          {
            value: 'NAO_NESCESSITA_INSCRICAO',
            nome: 'Sem Inscrição'
          },
          {
            value: 'NAO_NESCESSITA_INSCRICAO_COM_FREQUENCIA',
            nome: 'Sem Inscrição(Com Frequência)'
          },
          {
            value: 'NAO_NESCESSITA_INSCRICAO_SEM_FREQUENCIA',
            nome: 'Sem Inscrição(Sem Frequência)'
          }
        ]
      },
      imagemSelecionada: '',
      editarImagem: false,
      opcaoEdicaoImg: 'nova-imagem',
      atualizando: false
    };
  },
  methods: {
    async handleSubmit() {
      if (this.editarImagem) {
        if (this.opcaoEdicaoImg == "apagar-imagem") {
          this.formData.imagem = null;
        }
      }
      try {
        this.atualizando = true;
        const atividadeId = this.dados.id;

        const response = await this.$api.put(`/atividade/${atividadeId}`, this.formData, {
          headers: {
            "Content-Type": "application/json",
          }
        });
        console.log(response.data)
        this.$emit('recarregarAtividades');
      } catch (error) {
        console.log(error);
        console.log(this.formData)
      }
    },
    processImage(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          this.formData.imagem.fileName = file.name; // Adicionado
          this.formData.imagem.mimeType = file.type;
          this.formData.imagem.base64Data = reader.result.split(",")[1];
          this.imagemSelecionada = reader.result;
        };
        reader.onerror = () => alert("Erro ao processar a imagem.");
        reader.readAsDataURL(file);
      }
    },
    async processarImagemAtividade() {
      try {
        const response = await fetch(this.dados.imagemUrl);
        const blob = await response.blob();

        this.formData.imagem.mimeType = blob.type;

        const reader = new FileReader();
        reader.onloadend = () => {
          this.formData.imagem.base64Data = reader.result;
        }
        reader.readAsDataURL(blob);
      } catch (error) {
        console.log(error);
      }
    },
    async cancelarAtividade() {
      const confirmacao = confirm('Você está prestes a cancelar essa atividade, Deseja prossegui?');
      if (confirmacao) {
        const atividadeId = this.dados.id
        try {
          this.$api.post(`/atividade/cancelar/${atividadeId}`);
          alert('Atividade cancelada com sucesso!');
        } catch (error) {
          alert(error);
        }
      }
    }
  },

  mounted() {
    this.processarImagemAtividade();
    console.log(this.dados)
  }
};
</script>

<style scoped>
#cadastro {
  z-index: 100;
}

#imgs {
  display: flex;
  flex-direction: column;
}

#imgs img {
  width: 100%;
  border-radius: 25px;
}

#imgs div {
  background-color: #e5dbfaff;
  padding: 2%;
  border-radius: 25px;
  margin: 0.5%;
  border: 2px solid #6f319e;
}
</style>
