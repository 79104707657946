<template>
  <div v-show="!atividadeSelecionada">
    <div class="selecao">
      <button class="nao-selecionado" :class="{'selecionado' : tipoAtividade === 'minhas atividades'}" @click="tipoAtividade = 'minhas atividades'">Todas Atividades</button>
      <button class="nao-selecionado" :class="{'selecionado' : tipoAtividade === 'atividades livres'}" @click="tipoAtividade = 'atividades livres'">Atividades Livres(Disponíveis)</button>
      <button class="nao-selecionado" :class="{'selecionado' : tipoAtividade === 'atividades com inscricao'}" @click="tipoAtividade = 'atividades com inscricao'">Atividades com Inscrição(Disponíveis)</button>
    </div>
    <div class="ordenacao-e-filtros">
      <div class="filtros">
        <h5>Filtros: {{ filtroSelecionado }}</h5>
        <div class="btn-group">
          <button class="btn btn-secondary btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown"
            aria-expanded="false">
            Tipo
          </button>
          <ul class="dropdown-menu">
            <li v-for="tipo in filtros.tipo"><button @click="filtroSelecionado = tipo">{{ tipo
                }}</button></li>
          </ul>
        </div>
        <div class="btn-group">
          <button class="btn btn-secondary btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown"
            aria-expanded="false">
            Modalidade
          </button>
          <ul class="dropdown-menu">
            <li v-for="modalidade in filtros.modalidade"><button @click="filtroSelecionado = modalidade">{{ modalidade
                }}</button></li>
          </ul>
        </div>
        <div>
          <button @click="filtroSelecionado = '', console.log(atividadesFiltradas)" class="limpar-filtros">Limpar
            filtros</button>
        </div>
      </div>
    </div>
    <div style="display: flex; flex-wrap: wrap">
      <div id="atividade" v-for="dadosAtividade in atividadesFiltradas">
        <h6 class="situacao" :class="{
          'programada': dadosAtividade.status === 'PROGRAMADA',
          'encerrada': dadosAtividade.status === 'ENCERRADA',
          'cancelada': dadosAtividade.status === 'CANCELADA',
          'inscricoes-abertas': dadosAtividade.status === 'INSCRICOES_ABERTAS',
          'em-execucao': dadosAtividade.status === 'EM_EXECUCAO'
        }">
          {{ formatarStatus(dadosAtividade.status) }}</h6>
        <div class="card" style="width: 18rem;">
          <img id="img" class="card-img-top" :src="dadosAtividade.imagemUrl" alt="Card image cap">
          <div class="card-body">
            <h5 class="card-title">{{ dadosAtividade.nome.toUpperCase() }}</h5>
            <p class="card-text">Descrição: {{ dadosAtividade.descricao }}</p>
            <p class="card-text">Tipo: {{ dadosAtividade.tipo }}</p>
            <p class="card-text">Modalidade: {{ dadosAtividade.modalidadeExecucao }}</p>
            <p class="card-text">Local: {{ dadosAtividade.local }}</p>
            <p class="card-text">Vagas ofertadas: {{ dadosAtividade.numeroDeVagasOfertadas || 'Não há vagas' }}</p>
            <p class="card-text">Início das Inscrições: {{ converterDatas(dadosAtividade.dataInicioInscricao) }}</p>
            <p class="card-text">Fim das Inscrições: {{ converterDatas(dadosAtividade.dataFimInscricao) }}</p>
            <p v-if="dadosAtividade.dataEncerramento" class="card-text">Encerramento: {{
              converterDatas(dadosAtividade.dataEncerramento) }}</p>
            <p v-if="dadosAtividade.dataCancelamento" class="card-text">
              Cancelada em: {{ converterDatas(dadosAtividade.dataCancelamento) }}
              <span v-if="dadosAtividade.motivoCancelamento">- Motivo: {{ dadosAtividade.motivoCancelamento }}</span>
            </p>
            <div class="botoes">
              <button @click="atividadeSelecionada = true, atividadeAberta = dadosAtividade"
                class="btn btn-primary">Saiba mais</button>
              <button @click="editarAtividade = true" class="btn btn-primary editar"
                v-show="isCoordenador && situacao === 'aberta'">Editar</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-show="atividadeSelecionada">
    <Atividade @fechar-atividade="fecharAtividade" :isAtiva="atividadeSelecionada" @titulo="abrirAtividade"
      :atividade="atividadeAberta" />
  </div>
</template>
<script setup>
import { getCurrentInstance, reactive, ref, watchEffect, computed, onMounted } from 'vue';
import Atividade from '@/components/Atividade.vue';
import { jwtDecode } from 'jwt-decode';

const emit = defineEmits(["paginaAberta"])

const props = defineProps({
  isAtiva: Boolean //Recebe um booleano de HomeView que defina a página como visível ou não
})
var atividadeSelecionada = ref(false);
var tipoAtividade = ref('minhas atividades');
const atividades = ref([]);
const atividadeAberta = ref(null);
const { proxy } = getCurrentInstance();

const filtros = {
  situacao: ['minhas atividades', 'atividades livres', 'atividades com inscricao'],
  modalidade: ['PRESENCIAL', 'ONLINE', 'HIBRIDA'],
  tipo: ['CURSO', 'MINICURSO', 'EVENTO', 'OFICINA', 'ENCONTRO']
}

var filtroSelecionado = ref('');

watchEffect(() => {
  if (!props.isAtiva) { //Se a página não estiver ativa
    atividadeSelecionada.value = false;
    emit('paginaAberta', false)
  } else {
    carregarAtividades();
  }
})

function abrirAtividade() {
  atividadeSelecionada.value = true;
  emit('paginaAberta', true)
}

function fecharAtividade() {
  atividadeSelecionada.value = false;
  emit('paginaAberta', false)
}

var situacao = ref('');
var editarAtividade = ref(false);

function converterDatas(data) {
  data = new Date(data);

  const dia = String(data.getDate()).padStart(2, '0');
  const mes = String(data.getMonth() + 1).padStart(2, '0');
  const ano = data.getFullYear();

  return `${dia}/${mes}/${ano}`;
}

function formatarStatus(status) {
  switch (status) {
    case 'PROGRAMADA':
      return 'Programada';
    case 'ENCERRADA':
      return 'Encerrada';
    case 'CANCELADA':
      return 'Cancelada';
    case 'INSCRICOES_ABERTAS':
      return 'Inscrições Abertas';
    case 'EM_EXECUCAO':
      return 'Em Execução';
    default:
      return status; // caso algum status inesperado seja encontrado
  }
}

async function analiseSituacao(data, atividade) {
  const atividadeId = atividade.id;
  const isInscrito = await analiseInscricao(atividadeId);
  console.log(isInscrito)

  if (atividade.status === 'CANCELADA') {
    situacao = 'cancelada';
    return 'Cancelada';
  } else if (new Date(data) <= new Date()) {
    situacao = 'encerrada';
    if (isInscrito) {
      return 'Inscrito';
    } else {
      return 'Encerrada';
    }
  } else if (new Date(data) > new Date()) {
    situacao = 'aberta';
    if (isInscrito) {
      return 'Inscrito';
    } else {
      return 'Aberta';
    }
  }
}

async function carregarAtividades() {
  try {
    const response = await proxy.$api.get('/atividade', {
      params: { page: 0, size: 50 }
    })

    atividades.value = response.data.content;
    atividades.value.forEach(async (atividade) => {
      atividade.situacao = await analiseSituacao(atividade.dataFim, atividade);
    })


    console.log(atividades)
  } catch (error) {
    console.log(error);
  }
}

const atividadesFiltradas = computed(() => {
  let statusSelecionado;
  if(tipoAtividade.value === 'atividades livres'){
    statusSelecionado = "NAO_NESCESSITA_INSCRICAO";
  } else if(tipoAtividade.value === 'atividades com inscricao') {
    statusSelecionado = "NESCESSITA_INSCRICAO";
  }
  return atividades.value.filter((atividade) => {
    return ((atividade.modalidadeExecucao.includes(filtroSelecionado.value) ||
      atividade.tipo.includes(filtroSelecionado.value)) && 
      (tipoAtividade.value === 'atividades livres' ? (atividade.modalidadeInscricao.includes(statusSelecionado) && ( atividade.status != 'ENCERRADA' && atividade.status != 'CANCELADA') ): false ||
      (atividade.modalidadeInscricao === statusSelecionado && ( atividade.status != 'ENCERRADA' && atividade.status != 'CANCELADA')) ||
      (tipoAtividade.value == 'minhas atividades' ? analiseInscricao(atividade.id) : false))
    )
  })
})

function getUserId() {
  const token = window.localStorage.getItem('auth_token');
  if (token) {
    try {
      const decodedToken = jwtDecode(token);
      return decodedToken.userId;
    } catch (error) {
      console.error('Erro ao decodificar o token:', error);
    }
  }
}

async function analiseInscricao(atividadeId) {
  const userId = getUserId();
  let inscrito = false;
  try {
    const response = await proxy.$api.get(`/participantes-atividades/${atividadeId}`, {
      params: { page: 0, size: 50 }
    });
    response.data.content.some((user) => {
      if (user.participanteId == userId) {
        console.log('foi')
        inscrito = true;
      }
    })
    console.log(response.data.content)
    console.log(inscrito)
    return inscrito;
  } catch (error) {
    console.log(error);
  }
}
</script>
<style scoped>
template {
  display: flex;
}

#atividade {
  padding-top: 4%;
  font-family: 'Poppins', sans-serif;
  background-color: #f7f7f7;
  color: #333;
}

.card {
  margin: 1.2rem;
  margin-top: 0;
  background-color: #e5dbfa;
  overflow: hidden;
  border-radius: 1rem;
}

.card-text {
  margin-bottom: 0.5rem;
}

.btn {
  margin-top: 5%;
  background-color: rgb(54, 32, 101);
  border-color: #362065;
}

.btn:hover {
  background-color: rgba(63, 22, 152, 0.7);
}

.situacao {
  color: #ffffff;
  margin: 0 0 -5% 1.2rem;
  padding: 2% 2% 2% 5%;
  width: 45%;
  height: 45px;
  border-radius: 15px 15px 0 0;
}

.programada {
  background-color: green;
}

.encerrada {
  background-color: red;
}

.cancelada {
  background-color: gray;
}

.inscricoes-abertas {
  background-color: #ff9800;
  width: 60%;
  /* Laranja */
}

.em-execucao {
  background-color: #2196f3;
  /* Azul */
}

p {
  color: #34495E;
  font-weight: 550;
}

#img {
  height: 25vh;
  background-color: #e9e9e9;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 0 1rem 1rem;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  position: relative;
  overflow: hidden;
  /* Ajustado para espaçamento */
}

#img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

#img:hover {
  transform: scale(1.05);
}

.botoes {
  display: flex;
  justify-content: space-between;
}

.editar {
  background-color: transparent;
  color: #362065;
}

.selecao{
  display: flex;
  margin-bottom: 1%;
  justify-content: space-between;
}

.nao-selecionado{
  height: 40px;
  width: 32%;
  border-radius: 10px;
  background-color: white;
}

.selecao button:hover{
  background-color: #362065;
  color: white;
  transition: 500ms;
}

.selecionado{
  background-color: #362065;
  color: white;
}

.ordenacao-e-filtros {
  display: flex;
  width: auto;
  border-bottom: 1px solid rgb(211, 211, 211);
  margin-bottom: 1%;
}

.filtros {
  width: 25%;
  margin-right: 2%;
  margin-bottom: 1%;
  border-right: 2px solid #6F319A;
}

.btn-group {
  margin: 0 5% 5% 0;
}

.btn:hover {
  background-color: #451e61;
  transition: 500ms;
}

.btn.show {
  background-color: #6F319A;
}

.limpar-filtros {
  width: 70%;
  border: 2px solid #6F319A;
  color: #6F319A;
  border-radius: 15px;
}

.card-title {
  text-align: center;
  font-size: 1.2rem;
  color: #34495E;
  font-weight: 600;
  border-bottom: 1px solid #34495E;
}

.dropdown-menu button {
  width: 100%;
  background-color: transparent;
  border: none;
  border-bottom: 2px solid black;
}
</style>