<template>
    <div id="botao-voltar">
        <button @click="$emit('fechar-atividade')">
            < voltar</button>
    </div>
    <div id="Atividade">
        <div id="head">
            <div id="Titulo">
                <h2>{{ dadosAtividade.nome }}</h2>
            </div>
            <div id="datas">
                <h6>Tipo: {{ dadosAtividade.tipo }}</h6>
                <h6>modalidade: {{ dadosAtividade.modalidadeExecucao }}</h6>
            </div>
        </div>
        <div id="conteudo">
            <section id="esq">
                <div id="img">
                    <figure>
                        <img :src="dadosAtividade.imagemUrl">
                    </figure>
                </div>
                <div id="descricao">
                    <h5>Descrição: </h5>
                    <h6>{{ dadosAtividade.descricao }}</h6>
                </div>
            </section>
            <section id="dir">
                <div id="infos">
                    <h4>Informações Importantes</h4>
                    <div>
                        <h6>Período Inscrição: {{ converterDatas(dadosAtividade.dataInicioInscricao) }} -  {{ converterDatas(dadosAtividade.dataFimInscricao) }}</h6>
                        <h6>Período Realização:</h6>
                        <h6>Local: {{ dadosAtividade.local }}</h6>
                        <h6 v-if="(dadosAtividade.numeroDeVagasOfertadas - dadosAtividade.numeroDeParticipantes) > 0">Vagas Restantes: {{ dadosAtividade.numeroDeVagasOfertadas - dadosAtividade.numeroDeParticipantes }}</h6>
                    </div>
                </div>
                <div id="encontros">
                    <h4>Encontros</h4>
                    <div v-if="ocorrencias.length">
                        <div class="encontro" v-for="ocorrencia in ocorrencias" :key="ocorrencia.ocorrenciaId">
                            <div>Descrição: {{ ocorrencia.descricao }}</div>
                            <div>Período: {{ converterDatas(ocorrencia.inicio_periodo) }}</div>
                            <div v-if="ocorrencia.motivoCancelamento">Motivo de Cancelamento:{{
                                ocorrencia.motivoCancelamento }}</div>
                        </div>
                    </div>
                    <p v-else>Nenhum encontro encontrado.</p>
                </div>
            </section>
        </div>

        <div v-show="!isInscrito">
            <div class="confirmacao" v-if="dadosAtividade.modalidadeInscricao === 'LIVRE'">
                <p>A atividade é aberta para todos</p>
            </div>
            <div class="confirmacao" v-else>
                <button @click="realizarInscricao" :disabled="carregando">Realizar Inscrição</button>
            </div>
        </div>
        <div class="confirmacao" v-show="isInscrito">
            Você já está inscrito nessa atividade
        </div>
        <div id="cancelamento" v-show="isInscrito">
            <button @click="cancelarInscricao">Cancelar Inscrição</button>
        </div>
    </div>
</template>

<script setup>
import { ref, computed, watchEffect, getCurrentInstance } from 'vue';
import { jwtDecode } from 'jwt-decode';

const carregando = ref(false);
const isInscrito = ref(false);
const ocorrencias = ref([]);
var participanteId;

const emit = defineEmits(["titulo", "fechar-atividade"]);

const props = defineProps({
    isAtiva: Boolean,
    atividade: Object
});

const dadosAtividade = computed(() => props.atividade || {
    nome: "Nome Padrão",
    descricao: "Descrição padrão",
    status: "PROGRAMADA",
    tipo: "CURSO",
    modalidadeExecucao: "PRESENCIAL",
    modalidadeInscricao: "NESCESSITA_INSCRICAO",
    criterioInscricao: "ORDEMDEINSCRICAO",
    numeroDeVagasOfertadas: 10,
    local: "Local Padrão",
    dataInicioInscricao: "2025-01-16T00:00:00",
    dataFimInscricao: "2025-01-22T00:00:00",
    imagemUrl: "https://bucket.nerds.cloudns.be/gex-dev/imagemDefault.jpeg",
    acaoUrl: ""
});

function converterDatas(data) {
    const date = new Date(data);
    const dia = String(date.getDate()).padStart(2, '0');
    const mes = String(date.getMonth() + 1).padStart(2, '0');
    const ano = date.getFullYear();
    return `${dia}/${mes}/${ano}`;
}

const { proxy } = getCurrentInstance();

async function realizarInscricao() {
    carregando.value = true;
    const userId = getUserId();
    const atividadeId = props.atividade.id;
    try {
        const response = await proxy.$api.post('/participantes-atividades', {
            atividadeId: atividadeId,
            usuarioId: userId
        });
        console.log(response);
    } catch (error) {
        console.error(error);
    }
    analiseInscricao();
    carregando.value = false;
}

function cancelarInscricao() {
    isInscrito.value = false;
}

watchEffect(() => {
    if (props.isAtiva && dadosAtividade.value) {
        sessionStorage.setItem('paginaHome', dadosAtividade.value.nome);
        emit('titulo');
        analiseInscricao();
        buscarOcorrencias();
    }
});

function getUserId() {
    const token = window.localStorage.getItem('auth_token');
    if (token) {
        try {
            const decodedToken = jwtDecode(token);
            return decodedToken.nome;
        } catch (error) {
            console.error('Erro ao decodificar o token:', error);
        }
    }
    return null;
}

async function analiseInscricao() {
    isInscrito.value = false;
    carregando.value = true;
    const atividadeId = props.atividade.id;
    const userName = getUserId();
    try {
        const response = await proxy.$api.get(`/participantes-atividades/${atividadeId}`, {
            params: { page: 0, size: 50 }
        });
        response.data.content.map((user) => {
            console.log(user);
            if (user.nome === userName) {
                participanteId = user.participanteId;
                isInscrito.value = true;
            }
        });
    } catch (error) {
        console.error(error);
    } finally {
        carregando.value = false;
    }
}

// Função para buscar as ocorrências da atividade
async function buscarOcorrencias() {
    carregando.value = true;
    const atividadeId = props.atividade.id;
    try {
        const response = await proxy.$api.get('/ocorrencias', {
            params: { atividadeId, page: 0, size: 10 }
        });
        ocorrencias.value = response.data.content || [];
        console.log(ocorrencias.value);
    } catch (error) {
        console.error('Erro ao buscar ocorrências:', error);
    } finally {
        carregando.value = false;
    }
}
</script>


<style scoped>
#head {
    border-bottom: 2px black solid;
    padding-bottom: 1%;
}

#conteudo {
    padding-top: 4%;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

#esq {
    width: 50%;
    border-right: 1px solid black;
    padding-right: 5%;
}

#dir {
    width: 50%;
    padding-left: 5%;
}

#descricao {
    padding: 5%;
}

#descricao h5 {
    border-bottom: 1px solid black;
    padding-bottom: 0.5%;
}

#infos {
    width: 100%;
    text-align: justify;
}

#infos div {
    margin-bottom: 5%;
}

#dir h4 {
    text-align: center;
    padding-bottom: 1%;
    border-bottom: 1px solid black;
}

#img {
    position: relative;
    overflow: hidden;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 45vh;
    width: 100%;
    background-color: #e9e9e9;
    border-radius: 2rem;
    border: 3px solid #362065ff;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.341);
}

#img h3 {
    position: absolute;
}

#img img {
    max-height: 100%;
    max-width: 100%;
    object-fit: cover;
}

.encontro {
    display: flex;
    justify-content: space-between;
    padding: 1%;
    border-radius: 50px;
    background-color: #e9e9e9;
}

#head {
    text-align: center;
}

#acao {
    padding-bottom: 5%;
    border-bottom: black solid 2px;
}

#descricao h5 {
    text-align: center;
}

.confirmacao {
    margin-bottom: 1%;
    display: flex;
    margin-top: 2%;
    height: 10vh;
    width: 100%;
    background-color: #e9e9e9;
    border: none;
    border-radius: 1.25rem;
    justify-content: center;
    align-items: center;
}

.confirmacao button {
    height: 100%;
    width: 100%;
    background-color: #e9e9e9;
    border: none;
    border-radius: 1.25rem;
}

.confirmacao button:hover {
    background-color: #d0d0d0;
    transition: 0.5s;
}

#cancelamento button {
    margin-bottom: 1%;
    height: 100%;
    width: 100%;
    background-color: red;
    color: white;
    border: none;
    border-radius: 1.25rem;
}
</style>