<template>
  <div id="noticias">
    <div class="cards">
      <div v-for="(noticia, index) in props.noticias.slice(0, 3)">
        <div class="card" style="width: 18rem;">
          <img :src="noticia.imagemUrl">
          <div class="card-body">
            <h5 class="card-title">{{ noticia.titulo }}</h5>
            <p class="card-text">{{ corteDeTexto(noticia.texto) }}.</p>
            <div class="botoes">
              <button class="btn btn-primary" @click="abrirNoticia(noticia), $emit('noticiaSelecionada', noticia)">Saiba
                mais</button>
              <button class="btn btn-primary editar" v-if="isCoordenador"
                @click="editarNoticia = true, noticiaAberta = noticia">Editar</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="lista" v-for="noticia in props.noticias.slice(3)">
      <div class="conteudo">
        <div class="imagem-lista">
          <img :src="noticia.imagemUrl">
        </div>
        <button class="corpo-noticia" @click="abrirNoticia(noticia), $emit('noticiaSelecionada', noticia)">
          <span>
            <span class="titulo">{{ noticia.titulo }}</span>
            <span class="info">{{ corteDeTexto(noticia.texto) }}</span>
          </span>
        </button>
        <button v-if="isCoordenador" class="lista-editar"
          @click="editarNoticia = true, noticiaAberta = noticia">Editar</button>
      </div>
    </div>
  </div>
  <div v-show="editarNoticia">
    <EditaNoticia @voltar="editarNoticia = false" @atualizarNoticias="$emit('atualizarNoticias')" :noticia="noticiaAberta" />
  </div>
</template>

<script setup>
import { reactive, ref, watchEffect } from 'vue';
import EditaNoticia from './EditaNoticia.vue';

const emit = defineEmits(["paginaAberta"])

var noticiaSelecionada = ref(false);
var noticiaAberta = ref({});
const editarNoticia = ref(false);

const props = defineProps({
  noticias: Object,
  isCoordenador: Boolean
})

watchEffect(() => {
  if (!props.isAtiva) {
    noticiaSelecionada.value = false;
    emit('paginaAberta', false)
  }
})

function abrirNoticia(noticia) {
  noticiaAberta.value = noticia;

  console.log(noticiaAberta.value)
  emit('paginaAberta', true)
  noticiaSelecionada.value = true;
}

function fecharNoticia() {
  noticiaSelecionada.value = false
  emit('paginaAberta', false)
}

function converterDatas(data) {
  data = new Date(data);

  const dia = String(data.getDate()).padStart(2, '0');
  const mes = String(data.getMonth() + 1).padStart(2, '0');
  const ano = data.getFullYear();

  return `${dia}/${mes}/${ano}`;
}

function corteDeTexto(texto) {
  if (texto.length > 400) {
    let novoTexto = texto.slice(0, 400);
    novoTexto += " ...";
    return novoTexto;
  } else {
    return texto;
  }
}
</script>

<style scoped>
.conteudo {
  height: 16vh;
  overflow: hidden;
  margin: 1% 4%;
  position: relative;
}

.corpo-noticia {
  border-radius: 0;
  display: flex;
  padding: 1%;
}

.lista-editar {
  position: absolute;
  width: 10%;
  height: 25%;
  bottom: 0;
  right: 0;
  font-size: 16px;
  color: white;
  padding: 0.5%;
  background-color: #362065;
  border-radius: 1rem 0 0 0;
}

#noticias {
  display: flex;
  flex-wrap: wrap;
}

.lista {
  width: 100%;
}

.cards {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.card {
  margin: 0 0 1.5rem 0;
  background-color: #e5dbfa;
  overflow: hidden;
  border-radius: 1rem;
}

.titulo {
  font-size: 25px;
  top: 0;
}

.imagem-lista {
  position: relative;
  overflow: hidden;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 20%;
  background-color: white;
}

.imagem-lista img {
  max-height: 100%;
  max-width: 100%;
  object-fit: cover;
}

.card-text {
  margin-bottom: 0;
}

.btn {
  margin-top: 5%;
  background-color: rgb(54, 32, 101);
  border-color: #362065;
}

.btn:hover {
  background-color: rgba(63, 22, 152, 0.7);
}

.situacao {
  color: #ffffff;
  margin: 0 0 -5% 1.2rem;
  padding: 2% 2% 2% 5%;
  width: 45%;
  height: 45px;
  border-radius: 15px 15px 0 0;
}

h5 {
  text-align: center;
  font-size: 1.1rem;
  color: #34495E;
  font-weight: 600;
  border-bottom: 1px solid #34495E;
}

p {
  color: #34495E;
  font-weight: 550;
}

#img {
  height: 25vh;
  background-color: #e9e9e9;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 0 1rem 1rem;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  position: relative;
  overflow: hidden;
  /* Ajustado para espaçamento */
}

#img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

#img:hover {
  transform: scale(1.05);
}

.botoes {
  display: flex;
  justify-content: space-between;
}

.editar {
  background-color: transparent;
  color: #362065;
}
</style>
