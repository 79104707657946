<template>
  <div id="atividade">
    <h6
      class="situacao"
      :class="{ 
        'programada': dadosAtividade.status === 'PROGRAMADA', 
        'encerrada': dadosAtividade.status === 'ENCERRADA', 
        'cancelada': dadosAtividade.status === 'CANCELADA',
        'inscricoes-abertas': dadosAtividade.status === 'INSCRICOES_ABERTAS',
        'em-execucao': dadosAtividade.status === 'EM_EXECUCAO' 
      }">
      {{ formatarStatus(dadosAtividade.status) }}
    </h6>
    <div class="card" style="width: 18rem;">
      <img id="img" class="card-img-top" :src="dadosAtividade.imagemUrl" alt="Imagem da atividade">
      <div class="card-body">
        <h5 class="card-title">{{ dadosAtividade.nome.toUpperCase() }}</h5>
        <p class="card-text">Descrição: {{ dadosAtividade.descricao }}</p>
        <p class="card-text">Tipo: {{ dadosAtividade.tipo }}</p>
        <p class="card-text">Modalidade: {{ dadosAtividade.modalidadeExecucao }}</p>
        <p class="card-text">Local: {{ dadosAtividade.local }}</p>
        <p class="card-text">Vagas: {{ dadosAtividade.numeroDeVagasOfertadas }}</p>
        <p class="card-text">Início das Inscrições: {{ converterDatas(dadosAtividade.dataInicioInscricao) }}</p>
        <p class="card-text">Fim das Inscrições: {{ converterDatas(dadosAtividade.dataFimInscricao) }}</p>
        <p v-if="dadosAtividade.dataEncerramento" class="card-text">Encerramento: {{ converterDatas(dadosAtividade.dataEncerramento) }}</p>
        <p v-if="dadosAtividade.dataCancelamento" class="card-text">
          Cancelada em: {{ converterDatas(dadosAtividade.dataCancelamento) }}
          <span v-if="dadosAtividade.motivoCancelamento">- Motivo: {{ dadosAtividade.motivoCancelamento }}</span>
        </p>
        <div class="botoes">
          <button @click="$emit('atividadeSelecionada', dadosAtividade)" class="btn btn-primary">Saiba mais</button>
          <button @click="editarAtividade = true" class="btn btn-primary editar" v-show="isCoordenador && dadosAtividade.status === 'PROGRAMADA'">Editar</button>
        </div>
      </div>
    </div>
  </div>
  <div v-show="editarAtividade">
    <editarAtividade @fechar-form-atividade="editarAtividade=false" @recarregarAtividades="$emit('recarregarAtividades')" :dados="dadosAtividade" :acaoId="acaoId"/>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import EditarAtividade from './EditarAtividade.vue';

const props = defineProps({
  dadosAtividade: Object,
  isCoordenador: Boolean,
  acaoId: String,
});

var editarAtividade = ref(false);

// Função para formatar o status
function formatarStatus(status) {
  switch (status) {
    case 'PROGRAMADA':
      return 'Programada';
    case 'ENCERRADA':
      return 'Encerrada';
    case 'CANCELADA':
      return 'Cancelada';
    case 'INSCRICOES_ABERTAS':
      return 'Inscrições Abertas';
    case 'EM_EXECUCAO':
      return 'Em Execução';
    default:
      return status; // caso algum status inesperado seja encontrado
  }
}

function converterDatas(data) {
  if (!data) return 'Não informado';
  data = new Date(data);

  const dia = String(data.getDate()).padStart(2, '0');
  const mes = String(data.getMonth() + 1).padStart(2, '0');
  const ano = data.getFullYear();

  return `${dia}/${mes}/${ano}`;
}
</script>

<style scoped>
#atividade {
  padding-top: 4%;
  font-family: 'Poppins', sans-serif;
  background-color: #f7f7f7;
  color: #333;
}

.card {
  margin: 1.2rem;
  margin-top: 0;
  background-color: #e5dbfa;
  overflow: hidden;
  border-radius: 1rem;
}

.card-text {
  margin-bottom: 0.5rem;
}

.btn {
  margin-top: 5%;
  background-color: rgb(54, 32, 101);
  border-color: #362065;
}

.btn:hover {
  background-color: rgba(63, 22, 152, 0.7);
}

.situacao {
  color: #ffffff;
  margin: 0 0 -5% 1.2rem;
  padding: 2% 2% 2% 5%;
  width: 45%;
  height: 45px;
  border-radius: 15px 15px 0 0;
}

.programada {
  background-color: green;
}

.encerrada {
  background-color: red;
}

.cancelada {
  background-color: gray;
}

.inscricoes-abertas {
  background-color: #ff9800; /* Laranja */
}

.em-execucao {
  background-color: #2196f3; /* Azul */
}

h5 {
  text-align: center;
  font-size: 1.1rem;
  color: #34495E;
  font-weight: 600;
  border-bottom: 1px solid #34495E;
}

p {
  color: #34495E;
  font-weight: 550;
}

#img {
  height: 25vh;
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

#img:hover {
  transform: scale(1.05);
}

.botoes {
  display: flex;
  justify-content: space-between;
}

.editar {
  background-color: transparent;
  color: #362065;
}
</style>
