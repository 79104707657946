<template>
  <div id="conteudo">
    <div v-show="pagina == 'acoes'">
      <Acoes @paginaAberta="abrirPagina" :isAtiva="paginaSelecionada.acoes.valor" />
    </div>
    <div v-show="pagina == 'atividades'">
      <Atividades @paginaAberta="abrirPagina" :isAtiva="paginaSelecionada.atividades.valor" />
    </div>
    <div v-show="pagina == 'noticias'">
      <MenuNoticias @paginaAberta="abrirPagina" :isAtiva="paginaSelecionada.noticias.valor" />
    </div>
    <div v-show="pagina == 'declaracoes-e-certificados'">
      <CertificadosEDeclaracoes />
    </div>
    <div id="home" v-show="pagina == ''">
      <section id="noticias-home" v-show="!paginaHomeAberta">
        <h3>Últimas Notícias</h3>
        <div class="filtros">
          <button @click="filtroNoticia = 'ufc'" style="border-right: 2px solid black;">UFC</button>
          <button @click="filtroNoticia = 'unidade'" style="border-right: 2px solid black;">Unidade</button>
          <button @click="filtroNoticia = 'prex'">PREX</button>
        </div>
        <div id="lista-noticias">
          <div class="lista" v-for="noticia in noticias">
            <div class="conteudo">
                <div class="imagem-lista">
                    <img :src="noticia.imagemUrl">
                </div>
                <button class="corpo-noticia" @click="abrirPagina(true), noticiaSelecionada = true, noticiaAberta = noticia">
                    <span>
                        <span class="titulo">{{ noticia.titulo }}</span>
                        <span class="info">{{ noticia.texto }}</span>
                    </span>
                </button>
            </div>
        </div>
        </div>
      </section>
      <section id="atividades" v-show="!paginaHomeAberta">
        <h5>Atividades de Extensão</h5>
        <div class="filtros">
          <button style="border-right: 2px solid black;">Unidade</button>
          <button style="border-right: 2px solid black;">UFC</button>
          <select name="outro" id="select-unidade">
            <option>Outra Unidade</option>
          </select>
        </div>
        <div class="conteudo atividade" v-for="atividade in atividades" style="width: 100%; margin-bottom: 5%">
          <button style="background-color: white; font-size: 1.1rem; font-weight: normal; border-radius: 0.75rem;"
            @click="abrirPagina(true), atividadeSelecionada = true, atividadeAberta = atividade">{{ atividade.nome
            }}</button>
        </div>
        <div id="ver-mais">
          <button @click="abrirSessao('Atividades')">Ver Mais</button>
        </div>
      </section>
      <div class="page" v-show="atividadeSelecionada && paginaHomeAberta">
        <Atividade @fechar-atividade="abrirPagina(false), atividadeSelecionada = false" :atividade="atividadeAberta" :isAtiva="atividadeSelecionada" />
      </div>
      <div class="page" v-show="noticiaSelecionada && paginaHomeAberta">
        <Noticia @fechar-noticia="abrirPagina(false), noticiaSelecionada = false" :dadosNoticia="noticiaAberta" />
      </div>
    </div>
  </div>
</template>

<script>
import { jwtDecode } from 'jwt-decode'
import eventBus from '@/eventBus'
import Acoes from '@/components/MenuAcoes.vue'
import Atividades from '@/components/MenuAtividades.vue'
import Noticia from '@/components/Noticia.vue'
import CertificadosEDeclaracoes from '@/components/MenuCertificadosEDeclaracoes.vue'
import Atividade from '@/components/Atividade.vue'
import MenuNoticias from '@/components/MenuNoticias.vue'

export default {
  data() {
    return {
      userName: null,
      pagina: '',
      paginaSelecionada: {
        acoes: { nome: 'acoes', valor: false },
        atividades: { nome: 'atividades', valor: false },
        noticias: { nome: 'noticias', valor: false },
        certificadosEDeclaracoes: { nome: 'certificados-e-declaracoes', valor: false },
      },
      atividades: [],
      noticias: [],
      filtroNoticia: '',
      paginaHomeAberta: false,
      atividadeSelecionada: false,
      noticiaSelecionada: false,
      atividadeAberta: null,
      noticiaAberta: null
    }
  },
  methods: {
    atualizarPagina(novaPagina) {
      if (this.pagina != novaPagina) {
        this.pagina = novaPagina;
        Object.values(this.paginaSelecionada).forEach((pagina) => {
          if (pagina.nome == novaPagina) {
            pagina.valor = true
          } else {
            pagina.valor = false
          }
        })
        eventBus.emit('paginaAberta', false)
      } else {
        this.pagina = '';
        this.paginaHomeAberta = false;
        this.atividadeSelecionada = false;
      }
    },

    abrirPagina(isSelecionada) {
      eventBus.emit('paginaAberta', isSelecionada);
      if (isSelecionada) {
        this.paginaHomeAberta = true;
      } else {
        this.paginaHomeAberta = false;
      }
    },

    async carregarAtividades() {
      try {
        const response = await this.$api.get('/atividade', {
          params: { page: 0, size: 50 }
        })

        this.atividades = response.data.content
      } catch (error) {
        console.log(error);
      }
    },

    async carregarNoticias() {
      const response = await this.$api.get('/noticia', {
        params: { page: 0, size: 10 }
      });
      response.data.content.map((noticia) => {
        this.noticias.push(noticia);
      })
      console.log(this.noticias)
    },

    converterDatas(data) {
      data = new Date(data);

      const dia = String(data.getDate()).padStart(2, '0');
      const mes = String(data.getMonth() + 1).padStart(2, '0');
      const ano = data.getFullYear();

      return `${dia}/${mes}/${ano}`;
    },

    abrirSessao(sessao) {
      eventBus.emit('abrirSessao', sessao);
    }
  },

  created() {
    eventBus.on('changeComponent', this.atualizarPagina);

    const token = window.localStorage.getItem('auth_token')
    if (token) {
      try {
        const decodedToken = jwtDecode(token)
        this.userName = decodedToken.sub
      } catch (error) {
        console.error('Erro ao decodificar o token:', error)
      }
    }
    sessionStorage.setItem('paginaHome', '');
    this.carregarAtividades();
    this.carregarNoticias();
  },
  beforeUnmount() {
    eventBus.off('changeComponent', this.atualizarPagina);
  },
  components: {
    Acoes,
    Atividades,
    Noticia,
    CertificadosEDeclaracoes,
    Atividade,
    MenuNoticias
  }
}
</script>

<style scoped>
.conteudo {
    height: 16vh;
    overflow: hidden;
}

.atividade {
  height: auto;
}

.corpo-noticia {
    border-radius: 0;
    display: flex;
    padding: 1%;
}

.imagem-lista {
    position: relative;
    overflow: hidden;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 20%;
    background-color: white;
}

.imagem-lista img {
    max-height: 100%;
    max-width: 100%;
    object-fit: cover;
}

.titulo {
    font-size: 25px;
    top: 0;
}

.page {
  width: 100%;
}

#conteudo {
  display: flex;
  flex-direction: column;
  width: auto;
  padding: 4%;
  padding-top: 2%;
}

#home {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

#noticias-home {
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.filtros {
  margin-bottom: 3%;
}

.filtros button {
  background-color: transparent;
  border: none;
}

.filtros button:hover {
  background-color: #362065;
  color: #f8f9fa;
  transition: 500ms;
}

#atividades {
  background-color: grey;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1% 2%;
  border-radius: 2rem;
  max-height: 80vh;
  overflow: hidden;
  background-color: #e5dbfa;
  position: fixed;
  right: 3%;
  box-shadow: 0px 0px 8px rgba(72, 42, 137, 0.45);
}

#lista-noticias {
  width: 100%;
}

#select-unidade {
  background-color: #e5dbfa;
  border: none;
}

#ver-mais {
  margin-top: 2%;
  width: 70%;
}

#ver-mais button {
  background-color: white;
  border-radius: 1.2rem;
  border: none;
  width: 100%;
  border: 2px solid #362065
}
</style>
<style>
.conteudo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(72, 42, 137);
  margin-bottom: 2%;
  border-radius: 2rem;
  box-shadow: 0 4px 2px rgba(72, 42, 137, 0.45);
}

.conteudo p {
  margin: 0 2% 0 1%;
  color: #ffffff;
}

.conteudo button {
  border: none;
  height: 100%;
  width: 100%;
  border-radius: 1.95rem;
  text-align: left;
  display: block;
  font-weight: 500;
  color: black;
  padding: 2%;
  font-size: 1.95rem;
  background-color: #e5dbfa;
}

.conteudo span {
  display: flex;
  flex-direction: column;
}

.info {
  color: rgb(0, 0, 0, 0.5);
  font-size: 15px;
  margin-left: 10px;
}

#botao-voltar button {
  background-color: #362065;
  color: white;
  padding: 12px 25px;
  border: none;
  border-radius: 5px;
  font-size: 1.2rem;
  margin-bottom: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

#botao-voltar button:hover {
  background-color: #6F319A;
}
</style>