<template>
  <div id="cadastro">
    <button @click.prevent="$emit('voltar')" id="voltar">voltar</button>
    <form class="form">
      <h4>Editar Notícia</h4>
      <div id="campos-form">
        <div class="mb-3">
          <label for="exampleFormControlInput1" class="form-label">Título*</label>
          <input type="text" class="form-control" v-model="noticia.titulo">
        </div>
        <div class="mb-3">
          <label for="exampleFormControlTextarea1" class="form-label">Conteúdo(texto)*</label>
          <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" v-model="noticia.texto"></textarea>
        </div>
        <div class="mb-3">
          <label for="formFile" class="form-label">Imagem(Opcional)</label>
          <input class="form-control" type="file" id="formFile" @change="coletarImagem($event)">
        </div>
        <div class="mb-3">
          <label for="exampleFormControlInput1" class="form-label">Créditos da imagem(Opcional)</label>
          <input type="text" class="form-control" id="exampleFormControlInput1"
            :disabled="!imagemSelecionada"><!--v-model="noticia.imagem.creditosImagem" não funciona-->
        </div>
        <div class="mb-3">
          <label for="exampleFormControlInput1" class="form-label">Palavras Chave*</label>
          <div style="display: flex;">
            <input type="text" class="form-control" v-model="palavra">
            <button id="adicionar-palavra" @click.prevent="adicionarPalavra(palavra), palavra = ''">Adicionar
              palavra</button>
          </div>
          <span v-for="palavra in palavrasChave">{{ palavra + ', ' }}</span>
        </div>
        <div style="border-bottom: 2px dotted rgb(0, 0, 0, 0.2); margin-bottom: 2%; padding-bottom: 1%;">
          Abrangência*
          <div v-for="abrangencia, index in abrangencias">
            <input class="form-check-input" type="radio" name="escopo" id="acao" :value="abrangencia.id"
              v-model="noticia.abrangenciaId">
            <label class="form-check-label" for="acao">
              {{ abrangencia.abrangencia }}
            </label>
          </div>
        </div>
        <div style="margin-bottom: 5%;">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" value=true id="flexCheckDefault"
              v-model="publicacaoProgramada">
            <label class="form-check-label" for="flexCheckDefault">
              Programar publicação?
            </label>
          </div>
          <div v-if="publicacaoProgramada">
            <p style="margin-bottom: 0;">Selecione a data:</p>
            <input type="date" v-model="noticia.dataPublicacao" id="inputData">
          </div>
        </div>
        <div class="mb-3">
          <label for="exampleFormControlInput1" class="form-label">Fonte</label>
          <input type="text" class="form-control" v-model="noticia.fonte">
        </div>
      </div>
      <div id="botoes">
        <button id="excluir" @click.prevent="excluirNoticia">Excluir</button>
        <button id="salvar" @click.prevent="">Salvar</button>
        <button id="enviar" @click.prevent="verificarNoticia">Enviar</button>
      </div>
    </form>
  </div>
</template>
<script setup>
import { Date } from 'core-js';
import { ref, reactive, getCurrentInstance, onMounted, computed, watchEffect } from 'vue';

const emit = defineEmits(["voltar", "atualizarNoticias"]);
const props = defineProps({
  noticia: Object
})

const publicacaoProgramada = ref(false);
var palavra = ref('');
var imagemSelecionada = ref(false);
const palavrasChave = reactive([]);
const abrangencias = ref([]);
const autor = reactive({});
const { proxy } = getCurrentInstance();

function coletarImagem(event) {
  const imagem = event.target.files[0];

  if (imagem) {
    imagemSelecionada.value = true;

    const extensao = imagem.name.split(".").pop().toLowerCase();
    const extensoesPermitidas = ['png', 'jpg', 'jpeg'];

    if (!extensoesPermitidas.includes(extensao)) {
      alert('arquivo não permitido');
      return;
    }
    noticia.imagem.mimeType = imagem.type;

    const leitor = new FileReader();
    leitor.readAsDataURL(imagem);

    leitor.onload = () => {
      noticia.imagem.base64Data = leitor.result.split(",")[1];
    }

    leitor.onerror = (error) => {
      console.error(error);
    }
  } else {
    imagemSelecionada.value = false;
  }

}

function adicionarPalavra(palavra) {
  if (palavra != '' && !palavrasChave.includes(palavra)) {
    palavrasChave.push(palavra);
  } else if (palavra == '') {
    alert('A palavra chave não pode ser vazia');
  } else {
    alert('Não é permitido palavras repetidas');
  }
}

const noticia = reactive({
  titulo: "",
  dataPublicacao: "",
  texto: "",
  fonte: "",
  palavrasChaves: palavrasChave,
  dataAutorizacao: "",
  abrangenciaId: "",
  autorId: "",
  imagem: {
    mimeType: "",
    base64Data: "",
    creditosImagem: ""
  }
})

function verificarAbrangencias(abrangencia) {
  abrangencias.value.map((abrangenciaRaiz) => {
    if (abrangenciaRaiz.abrangencia == abrangencia) {
      noticia.abrangenciaId = abrangenciaRaiz.id;
    }
  })
}

function carregarDadosNoticia() {
  noticia.titulo = props.noticia.titulo;
  noticia.dataPublicacao = props.noticia.dataPublicacao;
  noticia.texto = props.noticia.texto;
  noticia.fonte = props.noticia.fonte;
  noticia.autorId = autor.id;
}

async function processarImagemNoticia() {
  try {
    const response = await fetch(props.noticia.imagemUrl);
    const blob = await response.blob();

    noticia.imagem.mimeType = blob.type;

    const reader = new FileReader();
    reader.onloadend = () => {
      noticia.imagem.base64Data = reader.result;
    }
    reader.readAsDataURL(blob);
  } catch (error) {
    console.log(error);
  }
}

watchEffect(() => {
  if (props.noticia) {
    console.log(props.noticia)
    verificarAbrangencias(props.noticia.abrangenciaNome);
    carregarDadosNoticia();
    processarImagemNoticia();
  }
})

function dataHoje() {
  const hoje = new Date();
  const dia = hoje.getDate();
  const mes = hoje.getMonth() + 1;
  const ano = hoje.getFullYear();

  const dataFormatada = `${ano}-${mes.toString().padStart(2, '0')}-${dia.toString().padStart(2, '0')}`;

  return dataFormatada
}

function verificarNoticia() {
  let dataValida = false;

  if (publicacaoProgramada.value) {
    if (noticia.dataPublicacao != '') {
      if (noticia.dataPublicacao >= dataHoje()) {
        dataValida = true;
      } else {
        return alert('A data deve ser no futuro');
      }
    } else {
      return alert('Informe a data de publicação por favor');
    }
    /*} else {
        let data = new Date(dataHoje())
        if (noticia.dataPublicacao != '') {
            if (data.setDate(data.getDate() + 5) <= new Date(noticia.dataPublicacao)) {
                dataValida = true;
            } else {
                return alert('Para noticias publicadas fora da ação o tempo minímo para programação é de 5(Cinco) dias, contados a partir da data de hoje');
            }
        } else {
            return alert('Informe a data de publicação por favor');
        }
    }*/
  } else {
    dataValida = true;
    noticia.dataPublicacao = dataHoje();
  }

  if (noticia.titulo != '' && dataValida) {
    if (noticia.conteudo != '') {
      console.log(noticia);
      editarNoticia();
    } else {
      return alert('O conteúdo da notícia não pode ser vazio');
    }
  } else {
    return alert('A notícia deve ter título');
  }
}

async function editarNoticia() {
  const noticiaId = props.noticia.id;
  try {
    const response = await proxy.$api.put(`/noticia/${noticiaId}`, noticia)
    console.log(response);
    alert("Notícia atualizada com sucesso");
    emit('voltar');
    emit('atualizarNoticias');
  } catch (error) {
    alert(error.data.message)
  }
}

async function getAbrangencias() {
  const response = await proxy.$api.get('/abrangencia-noticia');
  abrangencias.value = response.data;
  console.log(abrangencias)
}

async function getAutor() {
  const response = await proxy.$api.get('/autor-noticia');
  autor.id = response.data[0].id;
  autor.nome = response.data[0].autoNoticia;
  noticia.autorId = autor.id;
}

async function excluirNoticia() {
  const noticiaId = props.noticia.id;
  const alerta = confirm('Você esta prestes a excluir a notícia ');

  if (alerta) {
    try {
      proxy.$api.get(`/noticia/excluir/${noticiaId}`);
      emit('atualizarNoticias');
    } catch (error) {
      alert(error.data.message)
    }
  }
}

onMounted(() => {
  getAbrangencias();
  getAutor();
})
</script>
<style scoped>

</style>