<template>
  <div id="conteudo-acao" v-show="!telaAberta">
    <div id="botao-voltar">
      <button @click="$emit('fechar-acao')">
        < voltar </button>
    </div>

    <div id="titulo">
      <h2>{{ dadosAcao.titulo }}</h2>
      <h4>Coordenador: {{ dadosAcao.coordenador }}</h4>
    </div>

    <div id="menus">
      <div class="item" :class="{ 'opcao-selecionada': pagina === 'atividades' }">
        <button @click.prevent="pagina = 'atividades'">Atividades</button>
      </div>

      <!-- Verifica se o usuário é coordenador antes de mostrar o botão -->

      <div class="item" :class="{ 'opcao-selecionada': pagina === 'noticias' }">
        <button @click.prevent="pagina = 'noticias'">Notícias</button>
      </div>
      <div class="item" :class="{ 'opcao-selecionada': pagina === 'informacoes' }">
        <button @click.prevent="pagina = 'informacoes'">Informações</button>
      </div>
      <div class="item" :class="{ 'opcao-selecionada': pagina === 'declaracoes' }">
        <button @click.prevent="pagina = 'declaracoes'">{{ analiseTipoAcao() }}</button>
      </div>
      <div class="item" :class="{ 'opcao-selecionada': pagina === 'tarefas' }">
        <button @click.prevent="pagina = 'tarefas'">Tarefas</button>
      </div>
      <!--<div 
        class="item" 
        :class="{ 'opcao-selecionada': pagina === 'cadastrar-noticia' }">
        <button @click.prevent="pagina = 'cadastrar-noticia'">Adicionar notícia</button>
      </div>-->

      <div class="item" :class="{ 'opcao-selecionada': pagina === 'membro-time' }">
        <button @click.prevent="pagina = 'membro-time'">Central da Equipe</button>
      </div>
    </div>

    <FormAtividade v-if="acaoId" @fechar-form-atividade="pagina = ''" v-show="pagina === 'cadastrar-atividade'"
      :acao-id="acaoId" />

    <FormNoticia @fechar-form-noticia="pagina = ''" v-if="acaoId" v-show="pagina === 'cadastrar-noticia'"
      :acaoId="acaoId" :titulo="dadosAcao.titulo" />

    <div v-if="loading" class="spinner">
      <p>Carregando...</p>
    </div>

    <div v-else-if="pagina === 'atividades'">
      <div class="botao-cadastro" v-show="isCoordenador">
        <button @click.prevent="pagina = 'cadastrar-atividade'">Cadastrar Atividade</button>
      </div>
      <div id="atividades">
        <div v-for="(dadosAtividade, index) in atividades" :key="index">
          <AtividadeAcao :acaoId="acaoId" :isCoordenador="isCoordenador" :dadosAtividade="dadosAtividade"
            @recarregarAtividades="carregarAtividades()"
            @atividadeSelecionada="(atividade) => { atividadeAberta = atividade; abrirTela('atividade') }" />
        </div>
      </div>
      <div v-if="atividades.length === 0">
        <p class="sem-atividades">Nenhuma atividade encontrada.</p>
      </div>

      <div id="paginacao">
        <button @click="mudarPagina(paginaAtual - 1)" :disabled="paginaAtual === 0">Anterior</button>
        <button @click="mudarPagina(paginaAtual + 1)" :disabled="!hasNextPage">Próxima</button>
      </div>

    </div>

    <div v-else-if="pagina === 'membro-time'">
      <div v-for="(membroTime, index) in dadosMembroTime" :key="index">
        <MembroTimeAcao :dadosMembroTime="membroTime" />
      </div>
      <div v-if="dadosMembroTime.length === 0">
        <p class="membro-time">Nenhum memro do time encontrado.</p>
      </div>
    </div>

    <div v-else-if="pagina === 'noticias'">
      <div v-if="isCoordenador" class="botao-cadastro">
        <button @click.prevent="pagina = 'cadastrar-noticia'">Cadastrar Notícia</button>
      </div>
      <NoticiasAcao :isCoordenador="isCoordenador" :noticias="noticias" @noticiaSelecionada="(noticia) => { noticiaAberta = noticia, abrirTela('noticia') }" />
      
      <div v-if="noticias.length === 0">
        <p class="sem-atividades">Nenhuma notícia encontrada.</p>
      </div>

      <div id="paginacao">
        <button @click="mudarPagina(paginaAtual - 1)" :disabled="paginaAtual === 0">Anterior</button>
        <button @click="mudarPagina(paginaAtual + 1)" :disabled="!hasNextPage">Próxima</button>
      </div>
    </div>

    <div v-else-if="pagina === 'tarefas'">
      <div v-if="isCoordenador" class="botao-cadastro">
        <button @click.prevent="pagina = cadastro-atividades">
          Cadastrar tarefa
        </button>
      </div>
    </div>
    <h4 v-show="pagina === 'informacoes'">Informações da ação</h4>
    <h4 v-show="pagina === 'declaracoes'">{{ analiseTipoAcao() }}</h4>

  </div>
  <div id="atividade-selecionada" v-show="atividadeSelecionada">
    <div>
      <Atividade @fechar-atividade="fecharTela('atividade')" :atividade="atividadeAberta"
        :isAtiva="atividadeSelecionada" />
    </div>
  </div>
  <div id="noticia-selecionada" v-show="noticiaSelecionada">
    <div>
      <Noticia @fechar-noticia="fecharTela('noticia')" :dadosNoticia="noticiaAberta" :isAtiva="noticiaSelecionada" />
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch, watchEffect, getCurrentInstance } from 'vue';
import FormNoticia from './FormNoticia.vue';
import FormAtividade from './FormAtividade.vue';
import AtividadeAcao from './AtividadeAcao.vue';
import MembroTimeAcao from './MembroTimeAcao.vue';
import Atividade from './Atividade.vue';
import Noticia from './Noticia.vue';
import NoticiasAcao from './NoticiasAcao.vue';
import { jwtDecode } from 'jwt-decode';

const { proxy } = getCurrentInstance();

const atividades = ref([]);
const noticias = ref([]);
const dadosMembroTime = ref([]);
const pagina = ref('');
const paginaAtual = ref(0);
const loading = ref(false);
var telaAberta = ref(false);
var atividadeSelecionada = ref(false);
const noticiaSelecionada = ref(false);
const hasNextPage = ref(true);

var atividadeAberta = ref(null);
const noticiaAberta = ref(null);

const emit = defineEmits(["fechar-acao", "atualizarTitulo"]);
const props = defineProps({
  acao: Object,
  isAtiva: Boolean
});

const dadosAcao = computed(() => props.acao || {
  titulo: 'Título Padrão',
  coordenador: 'Coordenador Padrão',
  tipoAcao: 'Projeto'
});

const acaoId = ref(dadosAcao.value.acao_id);
const isCoordenador = ref(false); // Variável para armazenar se o usuário é coordenador

// Função para verificar o escopo do usuário
const checkCoordenador = () => {
  const token = window.localStorage.getItem("auth_token");
  if (token) {
    try {
      const decodedToken = jwtDecode(token);
      // Verifica se "COORDENADOR" está presente no campo scope
      isCoordenador.value = decodedToken.scope.split(' ').includes("COORDENADOR", "USUARIO");
    } catch (error) {
      console.error("Erro ao decodificar o token", error);
      isCoordenador.value = false;
    }
  }
};

// Verifica o escopo do usuário ao carregar o componente
checkCoordenador();

watch(() => props.acao, (newAcao) => {
  if (newAcao) {
    acaoId.value = newAcao.acao_id;
  }
}, { immediate: true });

watch(pagina, (newVal) => {
  if (newVal === 'atividades') {
    carregarAtividades();
  }
  if (newVal === 'membro-time') {
    carregarMembroTime();
  }
  if (newVal === 'noticias') {
    carregarNoticias();
  }
  paginaAtual.value = 0;
  hasNextPage.value = true;
});

watchEffect(() => {
  if (props.isAtiva && dadosAcao.value) {
    sessionStorage.setItem('paginaHome', dadosAcao.value.titulo);
    emit('atualizarTitulo')
  }
});


async function carregarMembroTime() {
  loading.value = true; // Início do carregamento
  try {
    const acao_id = dadosAcao.value.acao_id;
    const response = await proxy.$api.get(`/acoes/membros-time/${acao_id}`);

    if (response.data && Array.isArray(response.data)) {
      dadosMembroTime.value = response.data;

    } else {
      dadosMembroTime.value = [];
    }

    console.log(dadosMembroTime.value);


  } catch (error) {
    console.error('Erro ao carregar membros do time:', error);
  } finally {
    loading.value = false; // Fim do carregamento
  }
}

async function carregarAtividades(page = 0, size = 9) {
  loading.value = true; // Início do carregamento
  try {
    const acao_id = dadosAcao.value.acao_id;
    const response = await proxy.$api.get(`/atividade/acao/${acao_id}`, {
      params: { page, size }
    });

    if(paginaAtual.value >= response.data.totalPages -1 ){
      hasNextPage.value = false;
      console.log(hasNextPage.value)
    } else {
      hasNextPage.value = true;
    }

    if (response.data && Array.isArray(response.data.content)) {
      atividades.value = response.data.content;
    } else {
      atividades.value = [];
    }
  } catch (error) {
    console.error('Erro ao carregar atividades:', error);
  } finally {
    loading.value = false; // Fim do carregamento
  }
}

async function carregarNoticias(page = 0, size = 10) {
  loading.value = true;
  try {
    const acao_id = dadosAcao.value.acao_id;
    const response = await proxy.$api.get(`/noticia/acao/${acao_id}`, {
      params: { page, size }
    }
    );

    if(paginaAtual.value >= response.data.totalPages -1 ){
      hasNextPage.value = false;
      console.log(hasNextPage.value)
    } else {
      hasNextPage.value = true;
    }

    noticias.value = response.data.content;
    loading.value = false;
  } catch (error) {
    console.log(error);
  }
}

function mudarPagina(novaPagina) {
  if (novaPagina >= 0) {
    paginaAtual.value = novaPagina;
    if(pagina.value ==  'atividades'){
      carregarAtividades(novaPagina);
    } else if(pagina.value === 'noticias'){
      carregarNoticias(novaPagina);
    }
  }
}

function analiseTipoAcao() {
  return 'Minhas Declarações'
  return dadosAcao.value.tipoAcao === 'Curso' || 'Evento' ? 'Meus Certificados' : 'Minhas Declarações';
}

function abrirTela(tela) {
  if (tela == 'atividade') {
    atividadeSelecionada.value = true;
    telaAberta.value = true;
  } else if (tela == 'noticia') {
    telaAberta.value = true;
    noticiaSelecionada.value = true;
  }
}

function fecharTela(tela) {
  if (tela == 'atividade') {
    atividadeSelecionada.value = false;
    telaAberta.value = false;
    atividadeAberta.value = null;
  } else if (tela == 'noticia') {
    telaAberta.value = false;
    noticiaSelecionada.value = false;
    noticiaAberta.value = null;
  }
}
</script>

<style scoped>
/* Estilo do layout */
#conteudo-acao {
  display: flex;
  flex-direction: column;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

/* Título e Coordenador */
#titulo {
  text-align: center;
  margin-bottom: 20px;
}

#titulo h2 {
  font-size: 2rem;
  color: #343a40;
}

#titulo h4 {
  font-size: 1.25rem;
  color: #6c757d;
}

/* Menu de Navegação */
#menus {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 20px;
  border-bottom: 2px solid #343a40;
}

.item {
  display: flex;
  width: 100%;
}

#menus button {
  width: 100%;
  border: none;
  padding: 12px 20px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  border-radius: 5px;
}

.opcao-selecionada button {
  background-color: #362065;
  color: white;
}

#menus button:hover {
  background-color: rgba(54, 32, 101, 0.7);
  color: #ffffff;
}

/* Carregamento */
.spinner {
  text-align: center;
  padding: 20px;
  font-size: 1.2rem;
  color: #362065;
}

/* Sem atividades */
.sem-atividades {
  text-align: center;
  font-size: 1.2rem;
  color: #6c757d;
}

#paginacao {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

#paginacao button {
  padding: 10px 20px;
  font-size: 1.1rem;
  background-color: #362065;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

#paginacao button:hover {
  background-color: #6F319A;
}

#paginacao button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

#atividades {
  display: flex;
  flex-wrap: wrap;
  width: auto;
}

.botao-cadastro {
  margin-bottom: 2%;
}

.botao-cadastro button {
  border: none;
  padding: 12px 20px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  border-radius: 5px;
}

.botao-cadastro button:hover {
  background-color: rgba(54, 32, 101, 0.7);
  color: #ffffff;
}
</style>

<style>
#cadastro {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: rgb(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 80%;
  margin: 0 30%;
  background-color: white;
  border-radius: 1.5rem;
  padding: 2%;
}

.form h4 {
  width: 75%;
  border-bottom: 2px solid black;
  padding-bottom: 1%;
  text-align: center;
}

#voltar {
  margin-top: 6%;
  width: 15%;
  align-self: center;
  border: none;
  border-radius: 0.5rem 0.5rem 0 0;
  background-color: #EEE0F2;
}

#campos-form {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-top: 6%;
  width: 80%;
  padding: 0 2%;
  margin-bottom: 20px;
  overflow-y: scroll;
}

#campos-form input:focus {
  border: solid 2px #6F319A;
  box-shadow: none;
}

#campos-form textarea:focus {
  border: solid 2px #6F319A;
  box-shadow: none;
}

#campos-form select:focus {
  border: solid 2px #6F319A;
  box-shadow: none;
}

#campos-form input:checked {
  background-color: #6F319A;
  border-color: #6F319A;
}

#campos-form::-webkit-scrollbar {
  background-color: white;
  width: 10px;
}

#campos-form::-webkit-scrollbar-thumb {
  background-color: rgb(187, 187, 187);
  border-radius: 10px;
}

#botoes {
  display: flex;
  justify-content: space-between;
  width: 80%;
}

#botoes button {
  border: 2px solid #6F319A;
  border-radius: 10px;
  width: 25%;
}

#salvar {
  background-color: white;
  color: #6F319A;
}

#enviar {
  background-color: #6F319A;
  color: white;
}

#adicionar-palavra {
  border-radius: 8px;
  border-color: #6F319A;
  color: #6F319A;
}
</style>
